import client from './trackApiClient';

export async function fetchDailyGuestCount() {
  const { data } = await client.get('/guests/count/daily');

  return data;
}
export async function fetchWeeklyGuestCount() {
  const { data } = await client.get('/guests/count/weekly');

  return data;
}
export async function fetchMonthlyGuestCount() {
  const { data } = await client.get('/guests/count/monthly');

  return data;
}
