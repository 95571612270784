import { useCallback, useState } from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';
import fetchConsultationsByPage from '../service/fetchConsultationsByPage';
import paginatedConsultationsState from '../store/paginatedConsultationsState';
import useQueryParams from './useQueryParams';

export default function useConsultations() {
  const [paginatedConsultations, setPaginatedConsultations] = useRecoilState(
    paginatedConsultationsState,
  );
  const initializePaginatedConsultations = useResetRecoilState(paginatedConsultationsState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const pageSize = 20;
  const { consultations, isLastPage } = paginatedConsultations;
  const {
    page, aka, queryType, content, timestamp, category,
  } = useQueryParams();
  const currentPageCount = Number(page) || 1;

  const fetchConsultationsByQueryType = useCallback(async () => {
    if (!timestamp) {
      return null;
    }

    if (!queryType) {
      return fetchConsultationsByPage({
        page: currentPageCount, pageSize, timestamp,
      });
    }

    if (queryType === 'aka') {
      return fetchConsultationsByPage({
        page: currentPageCount, pageSize, timestamp, aka,
      });
    }

    if (queryType === 'content') {
      return fetchConsultationsByPage({
        page: currentPageCount, pageSize, timestamp, content,
      });
    }

    if (queryType === 'category') {
      return fetchConsultationsByPage({
        page: currentPageCount, pageSize, timestamp, category,
      });
    }
    return null;
  }, [aka, category, content, currentPageCount, queryType, timestamp]);

  const loadConsultations = useCallback(async () => {
    setIsLoading(true);
    const result = await fetchConsultationsByQueryType();
    if (result) {
      setPaginatedConsultations(result);
    }
    setIsLoading(false);
  }, [fetchConsultationsByQueryType, setPaginatedConsultations]);

  return {
    consultations,
    isLastPage,
    currentPageCount,
    initializePaginatedConsultations,
    loadConsultations,
    isLoading,
  };
}
