import client from './trackApiClient';

export async function fetchDailyUserCount() {
  const { data } = await client.get('/users/count/daily');

  return data;
}
export async function fetchWeeklyUserCount() {
  const { data } = await client.get('/users/count/weekly');

  return data;
}
export async function fetchMonthlyUserCount() {
  const { data } = await client.get('/users/count/monthly');

  return data;
}
