import client from './trackApiClient';
import PaginatedConsultations from '../types/PaginatedConsultations';

export default async function fetchConsultationsByPage({
  page, pageSize, timestamp, aka = null, content = null, category = null,
}:
{ page: number;
  pageSize: number;
  timestamp: string;
  aka?: string | null;
  content?: string | null;
  category?: string | null;
}): Promise<PaginatedConsultations> {
  const { data } = await client.get(`/consultations?page=${page}&pageSize=${pageSize}&timestamp=${timestamp}${aka ? `&aka=${aka}` : ''}${content ? `&content=${content}` : ''}${category ? `&category=${category}` : ''}`);
  return data;
}
