import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function GuestConsultRate() {
  const {
    weeklyGuestConsultRate,
    monthlyGuestConsultRate,
    loadWeeklyGuestConsultRate,
    loadMonthlyGuestConsultRate,
  } = useDashboard();

  useEffect(() => {
    loadWeeklyGuestConsultRate();
    loadMonthlyGuestConsultRate();
  }, [
    loadWeeklyGuestConsultRate,
    loadMonthlyGuestConsultRate,
  ]);
  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: weeklyGuestConsultRate.map((d) => d.date),
            y: weeklyGuestConsultRate.map((d) => d.rate),
            marker: { color: '#266EF1' },
            text: weeklyGuestConsultRate.map((d) => d.rate),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 400, title: { text: 'Weekly 방문자 상담 시작율' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: monthlyGuestConsultRate.map((d) => d.date),
            y: monthlyGuestConsultRate.map((d) => d.rate),
            marker: { color: '#266EF1' },
            text: monthlyGuestConsultRate.map((d) => d.rate),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 400, title: { text: 'Monthly 방문자 상담 시작율' } }}
      />
    </div>
  );
}
