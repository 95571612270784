import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import styled from 'styled-components';
import useDashboard from '../../hooks/useDashboard';

export default function AARFunnel() {
  const {
    aarFunnelData,
    loadAARFunnelData,
  } = useDashboard();

  useEffect(() => {
    loadAARFunnelData();
  }, [
    loadAARFunnelData,
  ]);

  const [selectedWeek, setSelectedWeek] = useState('');

  const Container = styled.div`
    display: flex;
    gap: 30px;
    
    select {
      height: 30px;
      width: 120px;
      background-color: #6ad86e;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  `;

  return (
    <Container>
      <select value={selectedWeek} onChange={(e) => setSelectedWeek(e.target.value)}>
        <option value="" disabled>선택하세요</option>
        {aarFunnelData.map((d) => (
          <option key={String(d.date)} value={String(d.date)}>
            {String(d.date)}
          </option>
        ))}
      </select>
      {
        aarFunnelData.map((d) => (
          (selectedWeek === String(d.date)) && (
            <Plot
              data={[
                {
                  type: 'funnel',
                  y: ['Acquisition:<br><br>방문자 수', 'Activation phase-1:<br><br>상담을 진행한 방문자 수', 'Activation phase-2:<br><br>회원가입 수', 'Retention:<br><br>전문가 연결 요청 수'],
                  x: Array.isArray(d.count) ? d.count : [],
                  textinfo: 'value+percent',
                  textposition: 'outside',
                  marker: {
                    color: ['#175bcc', '#068bee', '#a9c9ff', '#dee9fe'],
                  },
                  texttemplate: '%{value}<br>(%{percentInitial:.2%})',
                },
              ]}
              layout={{
                title: 'AAR 퍼널',
                width: 1500,
                height: 800,
                margin: {
                  l: 250,
                  r: 50,
                  b: 50,
                },
                yaxis: {
                  tickfont: {
                    size: 14,
                    color: '#000',
                  },
                },
              }}
            />
          )
        ))
      }

    </Container>
  );
}
