import client from './trackApiClient';

export async function fetchDailyMessagePerUser() {
  const { data } = await client.get('/message_per_user/daily');

  return data;
}
export async function fetchWeeklyMessagePerUser() {
  const { data } = await client.get('/message_per_user/weekly');

  return data;
}
export async function fetchMonthlyMessagePerUser() {
  const { data } = await client.get('/message_per_user/monthly');

  return data;
}
