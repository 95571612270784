import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function UserCount() {
  const {
    dailyUserCount,
    weeklyUserCount,
    monthlyUserCount,
    loadDailyUserCount,
    loadWeeklyUserCount,
    loadMonthlyUserCount,
  } = useDashboard();

  useEffect(() => {
    loadDailyUserCount();
    loadWeeklyUserCount();
    loadMonthlyUserCount();
  }, [
    loadDailyUserCount,
    loadWeeklyUserCount,
    loadMonthlyUserCount,
  ]);
  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: dailyUserCount.map((d) => d.date),
            y: dailyUserCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: dailyUserCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Daily 추가 회원가입 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: weeklyUserCount.map((d) => d.date),
            y: weeklyUserCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: weeklyUserCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Weekly 추가 회원가입 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: monthlyUserCount.map((d) => d.date),
            y: monthlyUserCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: monthlyUserCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Monthly 추가 회원가입 수' } }}
      />
    </div>
  );
}
