import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';

import useConsultation from '../hooks/useConsultation';

import removeAsterisks from '../utils/removeAsterisks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 1em;
`;

const Layout = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5em;
`;

const MessageBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: .5em;  
`;

const SubTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
`;

const Content = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #333;
  white-space: pre-wrap;
  display: flex;
  min-width: 3em;
`;

const ContentQuestion = styled.p`
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  color: #333;
  white-space: pre-wrap;
  display: flex;
  min-width: 3em;
`;

const ContentAnswer = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: #333;
  white-space: pre-wrap;
  display: flex;
  min-width: 3em;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: gray;
  flex-grow: 1;
`;

const Wall = styled.span`
  margin-inline: 1em;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default function ConsultationDetailPage() {
  const { consultationId } = useParams();
  const { consultation, loadConsultation } = useConsultation();

  useEffect(() => {
    if (!consultationId) {
      return;
    }
    loadConsultation(consultationId);
  }, [consultationId, loadConsultation]);

  if (!consultation) {
    return (
      <div>
        로딩중입니다!
      </div>
    );
  }
  const {
    aka, createdAt, updatedAt, dialogue, category, userId, phoneNumber,
  } = consultation;

  return (
    <Container>
      <Layout>
        <SubTitle>
          <strong>{aka}</strong>
          <Wall>||</Wall>
          <strong>유저 Id : </strong>
          {userId}
          <Wall>||</Wall>
          <strong>전화번호 : </strong>
          {phoneNumber}
          <Wall>||</Wall>
          <strong>카테고리 : </strong>
          {category}
        </SubTitle>
        <Border />
        <SubTitle>
          <strong>상담 생성 시간 : </strong>
          { dayjs(createdAt).format('M월DD일 HH시 mm분') }
          <Wall>||</Wall>
          <strong>마지막 질문 시간 : </strong>
          { dayjs(updatedAt).format('M월DD일 HH시 mm분') }
          <Wall>||</Wall>
          <strong>질문 갯수 : </strong>
          {dialogue.length}
        </SubTitle>
        <Messages>
          <Border />
          {Object.entries(
            groupBy(dialogue, (m) => dayjs(m.createdAt).format('M월DD일')),
          ).map(([date, messages]) => (
            messages.map((m, i) => (
              <div key={m.createdAt}>
                {i === 0 && (
                <SubTitle>
                  {date}
                </SubTitle>
                )}
                <MessageBox>
                  <Content>
                    { dayjs(m.createdAt).format('HH:mm') }
                  </Content>
                  <Content>
                    {m.role === 'client' ? '질문:' : '답변:'}
                  </Content>
                  {m.role === 'expert'
                    ? (
                      <ContentAnswer>
                        {removeAsterisks(m.content)}
                      </ContentAnswer>
                    )
                    : (
                      <ContentQuestion>
                        {m.content}
                      </ContentQuestion>
                    )}
                </MessageBox>
              </div>
            ))
          ))}
        </Messages>
      </Layout>
    </Container>
  );
}
