import { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import useConsultations from '../hooks/useConsultations';
import Consultations from '../components/consultations/Consultations';
import ConsultationsHead from '../components/consultations/ConsultationsHead';
import useQueryParams from '../hooks/useQueryParams';

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 4px;
padding: 16px;
  
  h1 {
    font-size: 32px;
    font-weight: 700;
    margin: 0;
  }
`;

export default function ConsultationsPage() {
  const {
    consultations,
    isLoading,
    loadConsultations,
  } = useConsultations();

  const {
    timestamp,
  } = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!timestamp) {
      const initialLoadTime = new Date().toISOString();
      const params = new URLSearchParams(location.search);
      params.set('timestamp', initialLoadTime);
      navigate(`${location.pathname}?${params.toString()}`);
      return;
    }
    loadConsultations();
  }, [loadConsultations, location.pathname, location.search, navigate, timestamp]);

  return (
    <Container>
      <h1>
        상담 목록 페이지
      </h1>
      <ConsultationsHead />
      <Consultations
        consultations={consultations}
        isLoading={isLoading}
      />
    </Container>
  );
}
