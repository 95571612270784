import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import styled from 'styled-components';
import useDashboard from '../../hooks/useDashboard';

export default function ConsultationCountPerDomain() {
  const {
    weeklyConsultationCountPerDomain,
    totalConsultationCountPerDomain,
    loadWeeklyConsultationCountPerDomain,
    loadTotalConsultationCountPerDomain,
  } = useDashboard();

  const [showWeekly, setShowWeekly] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState('');

  useEffect(() => {
    loadWeeklyConsultationCountPerDomain();
    loadTotalConsultationCountPerDomain();
  }, [
    loadWeeklyConsultationCountPerDomain,
    loadTotalConsultationCountPerDomain,
  ]);

  const Container = styled.div`
    display: flex;
    gap: 30px;
  `;

  const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      height: 40px;
      width: 120px;
      background-color: #4CAF50;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    button:hover {
      background-color: #45a049;
    }

    select {
      height: 30px;
      width: 120px;
      background-color: #6ad86e;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  `;

  return (
    <Container>
      <ButtonContainer>
        <button type="button" onClick={() => setShowWeekly(false)}>
          전체 보기
        </button>
        <button type="button" onClick={() => setShowWeekly(true)}>
          주간 보기
        </button>
        {showWeekly && (
        <select value={selectedWeek} onChange={(e) => setSelectedWeek(e.target.value)}>
          <option value="" disabled selected>선택하세요</option>
          {weeklyConsultationCountPerDomain.map((d) => (
            <option key={d.date} value={d.date}>
              {d.date}
            </option>
          ))}
        </select>
        )}
      </ButtonContainer>
      {(showWeekly
        ? (
          weeklyConsultationCountPerDomain.map((d) => (
            (showWeekly && (selectedWeek === String(d.date))) && (
            <Plot
              data={[
                {
                  values: Object.values(d.count),
                  labels: Object.keys(d.count),
                  type: 'pie',
                  hole: 0.4,
                  textinfo: 'label+percent',
                  textposition: 'auto',
                },
              ]}
              layout={{
                title: `${d.date} 도메인 분포`,
                height: 800,
                width: 1000,
              }}
            />
            )
          ))
        )
        : (
          <Plot
            data={[
              {
                values: Object.values(totalConsultationCountPerDomain),
                labels: Object.keys(totalConsultationCountPerDomain),
                type: 'pie',
                hole: 0.4,
                textinfo: 'label+percent',
                textposition: 'outside',
              },
            ]}
            layout={{
              title: '도메인 별 상담 수',
              height: 800,
              width: 1000,
            }}
          />
        ))}
    </Container>
  );
}
