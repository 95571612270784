import { ChangeEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PageNavigator from './PageNavigator';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 32px;
`;

const Search = styled.div`
  display: flex;
  gap: 4px;
  padding: 0 8px;
`;

const InputBox = styled.div`
  width: fit-content;
`;

type QueryType = 'aka' | 'content' | 'category' | 'consultationId' | null;
const categories = ['법률', '사주', '의료', '입시', '보험', '동물의료', '여행', '투자', '자동차', '연애', '약', '컴퓨터', '과외', '지원금', '헬스', '쇼핑', '커리어', '마케팅', '화장품', '부동산', '맛집', '대출', '무물'];

export default function ConsultationsHead() {
  const [queryType, setQueryType] = useState<QueryType>(null);
  const [query, setQuery] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  const initializeQuery = () => {
    setQueryType(null);
    setQuery('');
    setCategory('');
  };

  const search = () => {
    if (!queryType || (!query && !category)) {
      window.alert('검색 타입 혹은 검색어를 입력해주세요!!!');
      return;
    }

    if (queryType === 'consultationId') {
      const url = `/consultations/${query}`;
      window.open(url, '_blank');
      initializeQuery();
      return;
    }

    const params = new URLSearchParams();
    params.set('page', '1');
    params.set('queryType', queryType);

    if (queryType === 'aka') {
      params.set('aka', query);
    }

    if (queryType === 'content') {
      params.set('content', query);
    }

    if (queryType === 'category') {
      params.set('category', category);
    }

    navigate(`${location.pathname}?${params.toString()}`);
    initializeQuery();
  };
  const refreshSearch = () => {
    initializeQuery();
    navigate(`${location.pathname}`);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  };
  return (
    <Container>
      <PageNavigator />
      <Search>
        <InputBox className="input-group">
          <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {queryType || '검색 타입'}
          </button>
          <ul className="dropdown-menu">
            <li>
              <button className="dropdown-item" type="button" onClick={() => setQueryType('aka')}>AKA</button>
            </li>
            <li>
              <button className="dropdown-item" type="button" onClick={() => setQueryType('content')}>내용</button>
            </li>
            <li>
              <button className="dropdown-item" type="button" onClick={() => setQueryType('category')}>카테고리</button>
            </li>
            <li>
              <button className="dropdown-item" type="button" onClick={() => setQueryType('consultationId')}>상담Id</button>
            </li>
          </ul>
          {queryType === 'category'
            ? (
              <>
                <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {category || '카테고리'}
                </button>
                <ul className="dropdown-menu">
                  {categories.map((c) => (
                    <li key={c}>
                      <button className="dropdown-item" type="button" onClick={() => setCategory(c)}>{c}</button>
                    </li>
                  ))}
                </ul>
              </>
            )
            : <input type="text" value={query} onChange={handleChange} className="form-control" aria-label="Text input with dropdown button" />}
        </InputBox>
        <button type="button" className="btn btn-primary" onClick={search}>
          검색
        </button>
        <button type="button" className="btn btn-success" onClick={refreshSearch}>
          검색 초기화
        </button>
      </Search>
    </Container>
  );
}
