import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function TotalMessageCount() {
  const {
    dailyMessageCount,
    weeklyMessageCount,
    monthlyMessageCount,
    loadDailyMessageCount,
    loadWeeklyMessageCount,
    loadMonthlyMessageCount,
  } = useDashboard();

  useEffect(() => {
    loadDailyMessageCount();
    loadWeeklyMessageCount();
    loadMonthlyMessageCount();
  }, [
    loadDailyMessageCount,
    loadWeeklyMessageCount,
    loadMonthlyMessageCount,
  ]);

  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: dailyMessageCount.map((d) => d.date),
            y: dailyMessageCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: dailyMessageCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Daily 총 질문 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: weeklyMessageCount.map((d) => d.week),
            y: weeklyMessageCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: weeklyMessageCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Weekly 총 질문 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: monthlyMessageCount.map((d) => d.month),
            y: monthlyMessageCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: monthlyMessageCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Monthly 총 질문 수' } }}
      />
    </div>
  );
}
