import client from './trackApiClient';

export async function fetchWeeklyConsultationCountPerDomain() {
  const { data } = await client.get('/consultation_per_category/weekly');

  return data;
}
export async function fetchTotalConsultationCountPerDomain() {
  const { data } = await client.get('/consultation_per_category/total');

  return data;
}
