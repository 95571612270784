import styled from 'styled-components';

import palette from '../../styles/palette';

const Button = styled.button`
  padding: 8px 16px;
  background-color: ${palette.primary};
  border: none;
  border-radius: 5px;
  color: ${palette.textWhite};
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
      background-color: ${palette.shade2};
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
  }
`;

export default Button;
