import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function MessagePerUser() {
  const {
    dailyMessagePerUser,
    weeklyMessagePerUser,
    monthlyMessagePerUser,
    loadDailyMessagePerUser,
    loadWeeklyMessagePerUser,
    loadMonthlyMessagePerUser,
  } = useDashboard();

  useEffect(() => {
    loadDailyMessagePerUser();
    loadWeeklyMessagePerUser();
    loadMonthlyMessagePerUser();
  }, [
    loadDailyMessagePerUser,
    loadWeeklyMessagePerUser,
    loadMonthlyMessagePerUser,
  ]);
  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: dailyMessagePerUser.map((d) => d.date),
            y: dailyMessagePerUser.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: dailyMessagePerUser.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Daily 회원 인당 질문 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: weeklyMessagePerUser.map((d) => d.date),
            y: weeklyMessagePerUser.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: weeklyMessagePerUser.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Weekly 회원 인당 질문 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: monthlyMessagePerUser.map((d) => d.date),
            y: monthlyMessagePerUser.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: monthlyMessagePerUser.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Monthly 회원 인당 질문 수' } }}
      />
    </div>
  );
}
