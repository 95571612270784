import { styled } from 'styled-components';
import dayjs from 'dayjs';

import { Consultation } from '../../types/PaginatedConsultations';

const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 4px;
`;

const Header = styled.div`
  display: flex;
  border-bottom: 1px solid black;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2em;
  width: 15em;
`;

const Loading = styled.div`
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 100px;
    height: 100px;
  }
`;

export default function Consultations({
  consultations,
  isLoading,
}: {
  consultations: Consultation[];
  isLoading: boolean;
}) {
  const handleClickConsultationId = ({
    consultationId,
  }:{
    e: React.MouseEvent<HTMLDivElement>;
    consultationId: string;
  }) => {
    window.open(`/consultations/${consultationId}`, '_blank');
  };

  return (
    <Table>
      <Header>
        <Cell>상담 생성 시간</Cell>
        <Cell>마지막 질문 시간</Cell>
        <Cell>상담id</Cell>
        <Cell>카테고리</Cell>
        <Cell>A.K.A.</Cell>
        <Cell>전화번호</Cell>
        <Cell>
          총 질문 수
        </Cell>
      </Header>
      {isLoading ? (
        <Loading>
          <div className="spinner-border text-primary text-center" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </Loading>
      ) : (
        <Body>
          {consultations.map((c) => (
            <Row>
              <Cell>
                { dayjs(c.createdAt).format('M월DD일 HH시 mm분') || '-'}
              </Cell>
              <Cell>
                { dayjs(c.updatedAt).format('M월DD일 HH시 mm분') || '-'}
              </Cell>
              <Cell
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => handleClickConsultationId({ e, consultationId: c.id })}
              >
                {c.id || '-'}
              </Cell>
              <Cell>
                {c.category || '-'}
              </Cell>
              <Cell>
                {c.aka || '-'}
              </Cell>
              <Cell>
                {c.phoneNumber || '-'}
              </Cell>
              <Cell>
                {c.dialogueTurnCount || '-'}
              </Cell>
            </Row>
          ))}
        </Body>
      )}
    </Table>
  );
}
