import { useCallback, useState } from 'react';

import { fetchDailyConsultationCount, fetchMonthlyConsultationCount, fetchWeeklyConsultationCount } from '../service/fetchConsultationCount';
import { fetchDailyMessageCount, fetchMonthlyMessageCount, fetchWeeklyMessageCount } from '../service/fetchMessageCount';
import fetchDialogueLengthPerConsultation from '../service/fetchDialogueLengthPerConsultation';
import { fetchDailyMessagePerUser, fetchMonthlyMessagePerUser, fetchWeeklyMessagePerUser } from '../service/fetchMessagePerUser';
import { fetchDailyGuestCount, fetchMonthlyGuestCount, fetchWeeklyGuestCount } from '../service/fetchGuestCount';
import { fetchDailyUserCount, fetchMonthlyUserCount, fetchWeeklyUserCount } from '../service/fetchUserCount';
import { fetchWeeklyRegistrationRate, fetchMonthlyRegistrationRate } from '../service/fetchRegistrationRate';
import { fetchMonthlyGuestConsultRate, fetchWeeklyGuestConsultRate } from '../service/fetchGuestConsultRate';
import fetchUserDistributionByCategoryCount from '../service/fetchUserDistributionByCategoryCount';
import { fetchWeeklyConsultationCountPerDomain, fetchTotalConsultationCountPerDomain } from '../service/fetchConsultationCountPerDomain';
import fetchAARFunnelData from '../service/fetchAARFunnelData';
import { fetchDailyExpertRequestCount, fetchMonthlyExpertRequestCount, fetchWeeklyExpertRequestCount } from '../service/fetchExpertRequestCount';
import fetchExpertRequestCountPerDomain from '../service/fetchExpertRequestCountPerDomain';

export default function useDashboard() {
  // 전문가 요청 수
  const [
    dailyExpertRequestCount, setDailyExpertRequestCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    weeklyExpertRequestCount, setWeeklyExpertRequestCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    monthlyExpertRequestCount, setMonthlyExpertRequestCount,
  ] = useState<Record<string, string>[]>([]);

  // 도메인 별 전문가 요청 수
  const [
    expertRequestCountPerDomain, setExpertRequestCountPerDomain,
  ] = useState<Record<string, number>>({});

  // 회원의 인당 질문 수
  const [
    dailyMessagePerUser, setDailyMessagePerUser,
  ] = useState<Record<string, string>[]>([]);
  const [
    weeklyMessagePerUser, setWeeklyMessagePerUser,
  ] = useState<Record<string, string>[]>([]);
  const [
    monthlyMessagePerUser, setMonthlyMessagePerUser,
  ] = useState<Record<string, string>[]>([]);

  // AAR 퍼널
  const [
    aarFunnelData, setAARFunnelData,
  ] = useState<Record<string, string | number[]>[]>([]);

  // 상담 수
  const [
    dailyConsultationCount, setDailyConsultationCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    weeklyConsultationCount, setWeeklyConsultationCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    monthlyConsultationCount, setMonthlyConsultationCount,
  ] = useState<Record<string, string>[]>([]);

  // 메시지 수
  const [
    dailyMessageCount, setDailyMessageCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    weeklyMessageCount, setWeeklyMessageCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    monthlyMessageCount, setMonthlyMessageCount,
  ] = useState<Record<string, string>[]>([]);

  // 게스트 수
  const [
    dailyGuestCount, setDailyGuestCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    weeklyGuestCount, setWeeklyGuestCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    monthlyGuestCount, setMonthlyGuestCount,
  ] = useState<Record<string, string>[]>([]);

  // 회원 수
  const [
    dailyUserCount, setDailyUserCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    weeklyUserCount, setWeeklyUserCount,
  ] = useState<Record<string, string>[]>([]);
  const [
    monthlyUserCount, setMonthlyUserCount,
  ] = useState<Record<string, string>[]>([]);

  // 회원가입율
  const [
    weeklyRegistrationRate, setWeeklyRegistrationRate,
  ] = useState<Record<string, string>[]>([]);
  const [
    monthlyRegistrationRate, setMonthlyRegistrationRate,
  ] = useState<Record<string, string>[]>([]);

  // 상담시작율
  const [
    weeklyGuestConsultRate, setWeeklyGuestConsultRate,
  ] = useState<Record<string, string>[]>([]);
  const [
    monthlyGuestConsultRate, setMonthlyGuestConsultRate,
  ] = useState<Record<string, string>[]>([]);

  // 상담 하나 당 질문 수 분포
  const [
    guestDialogueLengthPerConsultation, setGuestDialogueLengthPerConsultation,
  ] = useState<Record<string, number>[]>([]);
  const [
    userDialogueLengthPerConsultation, setUserDialogueLengthPerConsultation,
  ] = useState<Record<string, number>[]>([]);

  // 회원 당 질문한 도메인 수 분포
  const [
    userDistributionByCategoryCount, setUserDistributionByCategoryCount,
  ] = useState<Record<string, number>[]>([]);

  // 도메인 별 상담 수
  const [
    weeklyConsultationCountPerDomain, setWeeklyConsultationCountPerDomain,
  ] = useState<Record<string, number>[]>([]);
  const [
    totalConsultationCountPerDomain, setTotalConsultationCountPerDomain,
  ] = useState<Record<string, number>>({});

  // 전문가 요청 수
  const loadDailyExpertRequestCount = useCallback(async () => {
    const fetched = await fetchDailyExpertRequestCount();
    setDailyExpertRequestCount(fetched.daily_requests_count);
  }, []);
  const loadWeeklyExpertRequestCount = useCallback(async () => {
    const fetched = await fetchWeeklyExpertRequestCount();
    setWeeklyExpertRequestCount(fetched.weekly_requests_count);
  }, []);
  const loadMonthlyExpertRequestCount = useCallback(async () => {
    const fetched = await fetchMonthlyExpertRequestCount();
    setMonthlyExpertRequestCount(fetched.monthly_requests_count);
  }, []);

  const loadExpertRequestCountPerDomain = useCallback(async () => {
    const fetched = await fetchExpertRequestCountPerDomain();
    setExpertRequestCountPerDomain(fetched);
  }, []);

  const loadDailyMessagePerUser = useCallback(async () => {
    const fetched = await fetchDailyMessagePerUser();
    setDailyMessagePerUser(fetched.daily_message_per_user);
  }, []);
  const loadWeeklyMessagePerUser = useCallback(async () => {
    const fetched = await fetchWeeklyMessagePerUser();

    setWeeklyMessagePerUser(fetched.weekly_message_per_user);
  }, []);
  const loadMonthlyMessagePerUser = useCallback(async () => {
    const fetched = await fetchMonthlyMessagePerUser();

    setMonthlyMessagePerUser(fetched.monthly_message_per_user);
  }, []);

  const loadAARFunnelData = useCallback(async () => {
    const fetched = await fetchAARFunnelData();
    setAARFunnelData(fetched);
  }, []);

  const loadDailyConsultationCount = useCallback(async () => {
    const fetched = await fetchDailyConsultationCount();
    setDailyConsultationCount(fetched.daily_consultations_count);
  }, []);
  const loadWeeklyConsultationCount = useCallback(async () => {
    const fetched = await fetchWeeklyConsultationCount();
    setWeeklyConsultationCount(fetched.weekly_consultations_count);
  }, []);
  const loadMonthlyConsultationCount = useCallback(async () => {
    const fetched = await fetchMonthlyConsultationCount();
    setMonthlyConsultationCount(fetched.monthly_consultations_count);
  }, []);

  const loadDailyMessageCount = useCallback(async () => {
    const fetched = await fetchDailyMessageCount();
    setDailyMessageCount(fetched.daily_messages_count);
  }, []);
  const loadWeeklyMessageCount = useCallback(async () => {
    const fetched = await fetchWeeklyMessageCount();
    setWeeklyMessageCount(fetched.weekly_messages_count);
  }, []);
  const loadMonthlyMessageCount = useCallback(async () => {
    const fetched = await fetchMonthlyMessageCount();
    setMonthlyMessageCount(fetched.monthly_messages_count);
  }, []);

  const loadDailyGuestCount = useCallback(async () => {
    const fetched = await fetchDailyGuestCount();
    setDailyGuestCount(fetched.daily_guests_count);
  }, []);

  const loadWeeklyGuestCount = useCallback(async () => {
    const fetched = await fetchWeeklyGuestCount();
    setWeeklyGuestCount(fetched.weekly_guests_count);
  }, []);

  const loadMonthlyGuestCount = useCallback(async () => {
    const fetched = await fetchMonthlyGuestCount();
    setMonthlyGuestCount(fetched.monthly_guests_count);
  }, []);

  const loadDailyUserCount = useCallback(async () => {
    const fetched = await fetchDailyUserCount();
    setDailyUserCount(fetched.daily_users_count);
  }, []);

  const loadWeeklyUserCount = useCallback(async () => {
    const fetched = await fetchWeeklyUserCount();
    setWeeklyUserCount(fetched.weekly_users_count);
  }, []);

  const loadMonthlyUserCount = useCallback(async () => {
    const fetched = await fetchMonthlyUserCount();
    setMonthlyUserCount(fetched.monthly_users_count);
  }, []);

  const loadWeeklyRegistrationRate = useCallback(async () => {
    const fetched = await fetchWeeklyRegistrationRate();
    setWeeklyRegistrationRate(fetched.weekly_registration_rates);
  }, []);

  const loadMonthlyRegistrationRate = useCallback(async () => {
    const fetched = await fetchMonthlyRegistrationRate();
    setMonthlyRegistrationRate(fetched.monthly_registration_rates);
  }, []);

  const loadWeeklyGuestConsultRate = useCallback(async () => {
    const fetched = await fetchWeeklyGuestConsultRate();
    setWeeklyGuestConsultRate(fetched.weekly_consult_rates);
  }, []);

  const loadMonthlyGuestConsultRate = useCallback(async () => {
    const fetched = await fetchMonthlyGuestConsultRate();
    setMonthlyGuestConsultRate(fetched.monthly_consult_rates);
  }, []);

  // 상담 하나 당 질문 수 분포
  const loadDialogueLengthPerConsultation = useCallback(async () => {
    const fetched = await fetchDialogueLengthPerConsultation();
    setGuestDialogueLengthPerConsultation(fetched.guest_length_distribution);
    setUserDialogueLengthPerConsultation(fetched.user_length_distribution);
  }, []);

  // 회원 당 질문한 도메인 수 분포
  const loadUserDistributionByCategoryCount = useCallback(async () => {
    const fetched = await fetchUserDistributionByCategoryCount();
    setUserDistributionByCategoryCount(fetched);
  }, []);

  // 도메인 별 상담 수
  const loadWeeklyConsultationCountPerDomain = useCallback(async () => {
    const fetched = await fetchWeeklyConsultationCountPerDomain();
    setWeeklyConsultationCountPerDomain(fetched.weekly_consultations_per_category_list);
  }, []);
  const loadTotalConsultationCountPerDomain = useCallback(async () => {
    const fetched = await fetchTotalConsultationCountPerDomain();
    setTotalConsultationCountPerDomain(fetched);
  }, []);

  return {
    dailyExpertRequestCount,
    weeklyExpertRequestCount,
    monthlyExpertRequestCount,
    loadDailyExpertRequestCount,
    loadWeeklyExpertRequestCount,
    loadMonthlyExpertRequestCount,
    expertRequestCountPerDomain,
    loadExpertRequestCountPerDomain,
    dailyMessagePerUser,
    weeklyMessagePerUser,
    monthlyMessagePerUser,
    loadDailyMessagePerUser,
    loadWeeklyMessagePerUser,
    loadMonthlyMessagePerUser,
    aarFunnelData,
    loadAARFunnelData,
    dailyConsultationCount,
    weeklyConsultationCount,
    monthlyConsultationCount,
    loadDailyConsultationCount,
    loadWeeklyConsultationCount,
    loadMonthlyConsultationCount,
    dailyGuestCount,
    weeklyGuestCount,
    monthlyGuestCount,
    loadDailyGuestCount,
    loadWeeklyGuestCount,
    loadMonthlyGuestCount,
    dailyUserCount,
    weeklyUserCount,
    monthlyUserCount,
    loadDailyUserCount,
    loadWeeklyUserCount,
    loadMonthlyUserCount,
    weeklyRegistrationRate,
    monthlyRegistrationRate,
    loadWeeklyRegistrationRate,
    loadMonthlyRegistrationRate,
    weeklyGuestConsultRate,
    monthlyGuestConsultRate,
    loadWeeklyGuestConsultRate,
    loadMonthlyGuestConsultRate,
    dailyMessageCount,
    weeklyMessageCount,
    monthlyMessageCount,
    loadDailyMessageCount,
    loadWeeklyMessageCount,
    loadMonthlyMessageCount,
    loadDialogueLengthPerConsultation,
    guestDialogueLengthPerConsultation,
    userDialogueLengthPerConsultation,
    userDistributionByCategoryCount,
    loadUserDistributionByCategoryCount,
    weeklyConsultationCountPerDomain,
    totalConsultationCountPerDomain,
    loadWeeklyConsultationCountPerDomain,
    loadTotalConsultationCountPerDomain,
  };
}
