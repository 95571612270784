import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function GuestCount() {
  const {
    dailyGuestCount,
    weeklyGuestCount,
    monthlyGuestCount,
    loadDailyGuestCount,
    loadWeeklyGuestCount,
    loadMonthlyGuestCount,
  } = useDashboard();

  useEffect(() => {
    loadDailyGuestCount();
    loadWeeklyGuestCount();
    loadMonthlyGuestCount();
  }, [
    loadDailyGuestCount,
    loadWeeklyGuestCount,
    loadMonthlyGuestCount,
  ]);
  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: dailyGuestCount.map((d) => d.date),
            y: dailyGuestCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: dailyGuestCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Daily 추가 방문자 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: weeklyGuestCount.map((d) => d.date),
            y: weeklyGuestCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: weeklyGuestCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Weekly 추가 방문자 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: monthlyGuestCount.map((d) => d.date),
            y: monthlyGuestCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: monthlyGuestCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Monthly 추가 방문자 수' } }}
      />
    </div>
  );
}
