import ConsultationCountPerDomain from './ConsultationCountPerDomain';
import DialogueLengthPerConsultation from './DialogueLengthPerConsultation';
import GuestConsultRate from './GuestConsultRate';
import GuestCount from './GuestCount';
import MessagePerUser from './MessagePerUser';
import RegistrationRate from './RegistrationRate';
import TotalConsultationCount from './TotalConsultationCount';
import TotalMessageCount from './TotalMessageCount';
import UserCount from './UserCount';
import UserCountPerDomainCount from './UserDistributionByCategoryCount,     loadUserDistributionByCategoryCount';
import AARFunnel from './AARFunnel';
import ExpertRequestCount from './ExpertRequestCount';
import ExpertRequestCountPerDomain from './ExpertRequestCountPerDomain';

export default function MenuData({ menu }: { menu: string; }) {
  const componentMap: Record<string, JSX.Element> = {
    '전문가 요청 수': <ExpertRequestCount />,
    '도메인 별 전문가 요청 수': <ExpertRequestCountPerDomain />,
    '회원의 인당 질문 수': <MessagePerUser />,
    'AAR 퍼널': <AARFunnel />,
    '전체 상담 수': <TotalConsultationCount />,
    '전체 질문 수': <TotalMessageCount />,
    '추가 방문자 수': <GuestCount />,
    '추가 회원가입 수': <UserCount />,
    회원가입율: <RegistrationRate />,
    '방문자 상담시작율': <GuestConsultRate />,
    '상담 하나 당 질문 수 분포': <DialogueLengthPerConsultation />,
    '회원 당 질문한 도메인 수 분포': <UserCountPerDomainCount />,
    '도메인 별 상담 수': <ConsultationCountPerDomain />,
  };

  return componentMap[menu] || null;
}
