import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function ExpertRequestCountPerDomain() {
  const {
    expertRequestCountPerDomain,
    loadExpertRequestCountPerDomain,
  } = useDashboard();

  useEffect(() => {
    loadExpertRequestCountPerDomain();
  }, [
    loadExpertRequestCountPerDomain,
  ]);

  return (
    <div>
      <Plot
        data={[
          {
            values: Object.values(expertRequestCountPerDomain),
            labels: Object.keys(expertRequestCountPerDomain),
            type: 'pie',
            hole: 0.4,
            textinfo: 'label+percent',
            textposition: 'outside',
          },
        ]}
        layout={{
          title: '도메인 별 전문가 요청 수',
          height: 800,
          width: 1000,
        }}
      />
    </div>
  );
}
