import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function TotalConsultationCount() {
  const {
    dailyConsultationCount,
    weeklyConsultationCount,
    monthlyConsultationCount,
    loadDailyConsultationCount,
    loadWeeklyConsultationCount,
    loadMonthlyConsultationCount,
  } = useDashboard();

  useEffect(() => {
    loadDailyConsultationCount();
    loadWeeklyConsultationCount();
    loadMonthlyConsultationCount();
  }, [
    loadDailyConsultationCount,
    loadWeeklyConsultationCount,
    loadMonthlyConsultationCount,
  ]);
  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: dailyConsultationCount.map((d) => d.date),
            y: dailyConsultationCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: dailyConsultationCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Daily 총 상담 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: weeklyConsultationCount.map((d) => d.date),
            y: weeklyConsultationCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: weeklyConsultationCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Weekly 총 상담 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: monthlyConsultationCount.map((d) => d.date),
            y: monthlyConsultationCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: monthlyConsultationCount.map((d) => d.count),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Monthly 총 상담 수' } }}
      />
    </div>
  );
}
