import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function RegistrationRate() {
  const {
    weeklyRegistrationRate,
    monthlyRegistrationRate,
    loadWeeklyRegistrationRate,
    loadMonthlyRegistrationRate,
  } = useDashboard();

  useEffect(() => {
    loadWeeklyRegistrationRate();
    loadMonthlyRegistrationRate();
  }, [
    loadWeeklyRegistrationRate,
    loadMonthlyRegistrationRate,
  ]);
  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: weeklyRegistrationRate.map((d) => d.date),
            y: weeklyRegistrationRate.map((d) => d.rate),
            marker: { color: '#266EF1' },
            text: weeklyRegistrationRate.map((d) => d.rate),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 400, title: { text: 'Weekly 회원가입율' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: monthlyRegistrationRate.map((d) => d.date),
            y: monthlyRegistrationRate.map((d) => d.rate),
            marker: { color: '#266EF1' },
            text: monthlyRegistrationRate.map((d) => d.rate),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 400, title: { text: 'Monthly 회원가입율' } }}
      />
    </div>
  );
}
