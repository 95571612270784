import client from './trackApiClient';

export async function fetchWeeklyRegistrationRate() {
  const { data } = await client.get('/registration_rate/weekly');

  return data;
}
export async function fetchMonthlyRegistrationRate() {
  const { data } = await client.get('/registration_rate/monthly');

  return data;
}
