import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function ExpertRequestCount() {
  const {
    dailyExpertRequestCount,
    weeklyExpertRequestCount,
    monthlyExpertRequestCount,
    loadDailyExpertRequestCount,
    loadWeeklyExpertRequestCount,
    loadMonthlyExpertRequestCount,
  } = useDashboard();

  useEffect(() => {
    loadDailyExpertRequestCount();
    loadWeeklyExpertRequestCount();
    loadMonthlyExpertRequestCount();
  }, [
    loadDailyExpertRequestCount,
    loadWeeklyExpertRequestCount,
    loadMonthlyExpertRequestCount,
  ]);
  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: dailyExpertRequestCount.map((d) => d.date),
            y: dailyExpertRequestCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: dailyExpertRequestCount.map((d) => d.count),
            textposition: 'outside',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Daily 전문가 요청 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: weeklyExpertRequestCount.map((d) => d.date),
            y: weeklyExpertRequestCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: weeklyExpertRequestCount.map((d) => d.count),
            textposition: 'outside',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Weekly 전문가 요청 수' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: monthlyExpertRequestCount.map((d) => d.date),
            y: monthlyExpertRequestCount.map((d) => d.count),
            marker: { color: '#266EF1' },
            text: monthlyExpertRequestCount.map((d) => d.count),
            textposition: 'outside',
          },
        ]}
        layout={{ width: 1500, height: 280, title: { text: 'Monthly 전문가 요청 수' } }}
      />
    </div>
  );
}
