import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function UserDistributionByCategoryCount() {
  const {
    userDistributionByCategoryCount,
    loadUserDistributionByCategoryCount,
  } = useDashboard();

  useEffect(() => {
    loadUserDistributionByCategoryCount();
  }, [
    loadUserDistributionByCategoryCount,
  ]);

  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: Object.keys(userDistributionByCategoryCount),
            y: Object.values(userDistributionByCategoryCount),
            marker: { color: '#266EF1' },
            text: Object.values(userDistributionByCategoryCount),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 800, title: { text: '카테고리 수 당 질문한 회원 수 분포' } }}
      />
    </div>
  );
}
