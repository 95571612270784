import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { RecoilRoot } from 'recoil';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import DashboardPage from './page/DashboardPage';
import ConsultationDetailPage from './page/ConsultationDetailPage';
import RouteGuard from './page/RouteGuard';
import LoginPage from './page/LoginPage';
import OtpPage from './page/OtpPage';
import HomePage from './page/HomePage';
import ConsultationsPage from './page/ConsultationsPage';

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <Routes>
          <Route path="/" element={<RouteGuard />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/consultations" element={<ConsultationsPage />} />
            <Route path="/consultations/:consultationId" element={<ConsultationDetailPage />} />
          </Route>
          <Route path="/otp" element={<OtpPage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
