import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import palette from '../styles/palette';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const Routers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const Router = styled.button`
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  padding: 16px 64px;
  border-radius: 25px;
  background-color: white;
  border: 1px solid black;
  color: black;
  transition: all 0.3s;
  
  &:hover {
    background-color: ${palette.primary};
    color: white;
  }
`;

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <Container>
      <h1>
        홈페이지
      </h1>
      <Routers>
        <Router onClick={() => navigate('/dashboard')}>
          대쉬보드 페이지
        </Router>
        <Router onClick={() => navigate('/consultations')}>
          상담 목록 페이지
        </Router>
      </Routers>
    </Container>
  );
}
