import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useConsultations from '../../hooks/useConsultations';

const Container = styled.nav`
  display: flex;
  justify-content: center;
  align-content: center;

  ul {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    > p {
      margin: 0;
      font-size: 20px;
    }
  }


  button {
    cursor: pointer;
  }
`;

export default function PageNavigator() {
  const {
    isLastPage,
    currentPageCount,
  } = useConsultations();
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (page: number) => {
    const params = new URLSearchParams(location.search);
    params.set('page', page.toString());
    navigate(`${location.pathname}?${params.toString()}`);
  };

  return (
    <Container>
      <ul className="pagination">
        {currentPageCount === 1 || (
        <li className="page-item">
          <button
            type="button"
            onClick={() => handleClick(currentPageCount - 1)}
            className="page-link"
          >
            {'<'}
          </button>
        </li>
        )}
        <p>
          현재 페이지 :
          {' '}
          {currentPageCount}
        </p>
        {isLastPage || (
        <li className="page-item">
          <button
            type="button"
            onClick={() => handleClick(currentPageCount + 1)}
            className="page-link"
          >
            {'>'}
          </button>
        </li>
        )}
      </ul>
    </Container>
  );
}
