import client from './trackApiClient';

export async function fetchDailyConsultationCount() {
  const { data } = await client.get('/consultations/count/daily');

  return data;
}
export async function fetchWeeklyConsultationCount() {
  const { data } = await client.get('/consultations/count/weekly');

  return data;
}
export async function fetchMonthlyConsultationCount() {
  const { data } = await client.get('/consultations/count/monthly');

  return data;
}
