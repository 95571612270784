import { useState } from 'react';
import styled from 'styled-components';
import Button from '../components/dashBoard/Button.jsx';
import palette from '../styles/palette.js';
import MenuData from '../components/dashBoard/MenuData.jsx';

const Container = styled.div`
  display: flex;
  height: 100vh;
  gap: 1em;
  border: 1px solid ${palette.containerLightGrey};
  border-radius: 10px;
  padding: 16px;
  background-color: ${palette.tint9};
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 120px;
  gap: 4px;
`;

const MenuButton = styled(Button)<{ $isSelected: boolean }>`
  display: flex;
  background-color: ${({ $isSelected }) => ($isSelected ? palette.darkPrimary : palette.primary)};
`;

const DataContainer = styled.div`
  flex: 1;
`;

export default function Dashboard() {
  const [selectedMenu, setSelectedMenu] = useState('전체');

  const menuList = [
    '전문가 요청 수',
    '도메인 별 전문가 요청 수',
    '회원의 인당 질문 수',
    'AAR 퍼널',
    '전체 상담 수',
    '전체 질문 수',
    '추가 방문자 수',
    '추가 회원가입 수',
    '회원가입율',
    '방문자 상담시작율',
    '상담 하나 당 질문 수 분포',
    '회원 당 질문한 도메인 수 분포',
    '도메인 별 상담 수',
  ];

  return (
    <Container>
      <Menu>
        {menuList.map((menu) => (
          <MenuButton
            onClick={() => setSelectedMenu(menu)}
            $isSelected={selectedMenu === menu}
          >
            {menu}
          </MenuButton>
        ))}
      </Menu>
      <DataContainer>
        <MenuData menu={selectedMenu} />
      </DataContainer>
    </Container>
  );
}
