import { atom } from 'recoil';
import PaginatedConsultations from '../types/PaginatedConsultations';

const paginatedConsultationsState = atom<PaginatedConsultations>({
  key: 'consultationsState',
  default: {
    consultations: [],
    isLastPage: false,
  },
});

export default paginatedConsultationsState;
