import client from './trackApiClient';

export async function fetchWeeklyGuestConsultRate() {
  const { data } = await client.get('/consult_rate/weekly');

  return data;
}
export async function fetchMonthlyGuestConsultRate() {
  const { data } = await client.get('/consult_rate/monthly');

  return data;
}
