import client from './trackApiClient';

export async function fetchDailyMessageCount() {
  const { data } = await client.get('/messages/count/daily');

  return data;
}
export async function fetchWeeklyMessageCount() {
  const { data } = await client.get('/messages/count/weekly');

  return data;
}
export async function fetchMonthlyMessageCount() {
  const { data } = await client.get('/messages/count/monthly');

  return data;
}
