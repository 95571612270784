import { useEffect } from 'react';
import Plot from 'react-plotly.js';
import useDashboard from '../../hooks/useDashboard';

export default function DialogueLengthPerConsultation() {
  const {
    guestDialogueLengthPerConsultation,
    userDialogueLengthPerConsultation,
    loadDialogueLengthPerConsultation,
  } = useDashboard();

  useEffect(() => {
    loadDialogueLengthPerConsultation();
  }, [
    loadDialogueLengthPerConsultation,
  ]);

  return (
    <div>
      <Plot
        data={[
          {
            type: 'bar',
            x: Object.keys(guestDialogueLengthPerConsultation),
            y: Object.values(guestDialogueLengthPerConsultation),
            marker: { color: '#266EF1' },
            text: Object.values(guestDialogueLengthPerConsultation),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 400, title: { text: 'Guest' } }}
      />
      <Plot
        data={[
          {
            type: 'bar',
            x: Object.keys(userDialogueLengthPerConsultation),
            y: Object.values(userDialogueLengthPerConsultation),
            marker: { color: '#266EF1' },
            text: Object.values(userDialogueLengthPerConsultation),
            textposition: 'auto',
          },
        ]}
        layout={{ width: 1500, height: 400, title: { text: 'User' } }}
      />
    </div>
  );
}
