import client from './trackApiClient';

export async function fetchDailyExpertRequestCount() {
  const { data } = await client.get('/requests/count/daily');

  return data;
}
export async function fetchWeeklyExpertRequestCount() {
  const { data } = await client.get('/requests/count/weekly');

  return data;
}
export async function fetchMonthlyExpertRequestCount() {
  const { data } = await client.get('/requests/count/monthly');

  return data;
}
